import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Pencil, ShareNetwork } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  TRACK_VALUE
} from '../../../../common/constants';
import useDeviceInfo from '../../../../common/useDeviceInfo';
import useNetworkType from '../../../../common/useNetworkInfo';
import { getUserCommonDimensions } from '../../../../common/utils';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import ModalComponent from '../customPlayer/ModalComponent';
import ShareSheet from '../customPlayer/ShareSheet';

function FormCard({
  href = '#',
  title = 'Presidential Candidates',
  background = 'bg-n-700',
  cta,
  showCta,
  instanceData,
  instanceId,
  instanceType
}) {
  const {
    state: { currentUser }
  } = useApp();
  const router = useRouter();
  const [visible, setVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();

  useEffect(() => {
    const currentUrl = window?.location?.href;
    const cleanedUrl = currentUrl.replace(/\/$/, '');
    setShareUrl(cleanedUrl);
  }, []);

  const handleForm = (category, action, formUrl) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: TRACK_VALUE.SUCCESS
    });
    router?.push(formUrl);
  };

  return (
    <div className={`form-card ${background}`}>
      <div className="vc-description">
        <ModalComponent
          className="share-popup"
          title="Share"
          open={visible}
          onCancel={() => setVisible(false)}
          footer={false}
          mask={false}
        >
          <ShareSheet shareUrl={`${shareUrl}${cta?.[0]?.actionUrl ?? href}`} />
        </ModalComponent>
        <div
          className="text-md-bold block fc-title text-black-90 hc-black-70 pointer"
          onClick={() =>
            handleForm(
              MATOMO_CATEGORY.VIEW_DETAIL,
              MATOMO_ACTION.VIEW_FORM,
              href
            )
          }
        >
          <Pencil size={24} /> {title}
        </div>
        <div className="form-card-button-wrapper">
          {showCta?.share && (
            <Button
              icon={<ShareNetwork size={16} weight="bold" />}
              size="small"
              className="d-flex align-center justify-center b-0 bg-n-900 hc-white-90 text-small"
              block
              onClick={() => {
                trackEvent({
                  category: MATOMO_CATEGORY.BUTTON,
                  action: MATOMO_ACTION.CLICK,
                  name: MATOMO_NAME.BUTTON_CLICK,
                  customDimensions: [
                    {
                      id: actionDimensionIds.page_source,
                      value: router.asPath
                    },
                    {
                      id: actionDimensionIds.cta_title,
                      value: 'Share'
                    },
                    ...getUserCommonDimensions(currentUser, device, networkType)
                  ]
                });
                setVisible(true);
              }}
            >
              Share
            </Button>
          )}
          {cta?.length > 0 && (
            <Button
              icon={
                cta?.[0]?.icon?.url && (
                  <Image
                    src={cta?.[0]?.icon?.url}
                    width={16}
                    height={16}
                    alt="icon"
                  />
                )
              }
              size="small"
              block
              href={cta?.[0]?.actionUrl ?? href}
              className="d-flex align-center justify-center b-0 bg-n-900 hc-white-90 text-white-90 text-small"
              onClick={() => {
                trackEvent({
                  category: MATOMO_CATEGORY.BUTTON,
                  action: MATOMO_ACTION.CLICK,
                  name: MATOMO_NAME.BUTTON_CLICK,
                  customDimensions: [
                    {
                      id: actionDimensionIds.page_source,
                      value: router.asPath
                    },
                    {
                      id: actionDimensionIds.cta_title,
                      value: cta?.[0]?.label
                    },
                    ...getUserCommonDimensions(currentUser, device, networkType)
                  ]
                });
              }}
            >
              {cta?.[0]?.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormCard;
