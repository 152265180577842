import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Avatar, Button } from 'antd';
import isFunction from 'lodash/isFunction';
import { useRouter } from 'next/router';
import React from 'react';
import {
  ACTION_BUTTON_TYPES,
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  PAGE_SLUG
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import { getUserCommonDimensions } from '../common/utils';
import { useApp } from '../providers/AppContext';

function ActionButton({
  children,
  type,
  url,
  onClick,
  internalPageType,
  icon,
  primaryBtn,
  secondaryBtn,
  className,
  ...rest
}) {
  const router = useRouter();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const { trackEvent } = useMatomo();
  const {
    state: { currentUser }
  } = useApp();

  const formContentDimension = (id, articleTitle, link) => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );
    const contentObjectForVideo = [
      { id: actionDimensionIds.content_title, value: children },
      { id: actionDimensionIds.content_slug, value: link },
      ...userDimensions
    ];
    return contentObjectForVideo;
  };
  const handleClick = () => {
    trackEvent({
      category: MATOMO_CATEGORY.BUTTON,
      action: MATOMO_ACTION.CLICK,
      name: MATOMO_NAME.BUTTON_CLICK,
      customDimensions: formContentDimension()
    });
    if (isFunction(onClick)) onClick();
    if (type === ACTION_BUTTON_TYPES.INTERNAL) {
      router.push(
        `${PAGE_SLUG[internalPageType] ?? ''}${url === '/' ? '' : '/'}${url}`
      );
    } else if (
      typeof window !== 'undefined' &&
      type === ACTION_BUTTON_TYPES.EXTERNAL_IN_APP
    ) {
      // open the url in the same tab when action type is external in app
      window.open(url, '_self');
    } else window.open(url, '_blank');
  };

  return (
    <Button
      onClick={handleClick}
      className={`cs-button ${className}`}
      type={`${primaryBtn ? 'primary' : 'default'}`}
      {...rest}
    >
      {icon?.url ? <Avatar className="mr-8" src={icon?.url} size={22} /> : ''}{' '}
      {children}
    </Button>
  );
}

export default ActionButton;
