/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Form, Input, Select, Typography } from 'antd';
import axios from 'axios';
import { lowerCase } from 'lodash';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  TRACK_VALUE
} from '../../../../common/constants';
import { SUBMIT_FORM } from '../../../../graphql/Mutations';
import { GET_FORM_DATA } from '../../../../graphql/Queries';
import { toast } from '../../../../lib/apollo';
import { gaEvent } from '../../../../lib/gtag';
import { AppContext } from '../../../../providers/AppContext';
import FormDisplayLoading from '../../../skeleton/FormDisplayLoading';
import Loader from '../../Loader';
import SectionHeader from '../../SectionHeader/SectionHeader';

const { Paragraph } = Typography;
function FormDisplay({ sectionHeading, headerDescription, data = {} }) {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const { trackEvent } = useMatomo();
  const [actionType, setActionType] = useState({ type: 'SUBMIT', url: '' });
  const [form] = Form?.useForm();
  const { data: session } = useSession();
  const token = session?.accessToken;
  const [buttonClickedIndex, setButtonClickedIndex] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const category = MATOMO_CATEGORY.FORM;
  const action = MATOMO_ACTION.FORM_SIGNED;
  const statusSuccess = TRACK_VALUE.SUCCESS;
  const statusFail = TRACK_VALUE.FAIL;
  const router = useRouter();
  const [submitForm, { loading }] = useMutation(SUBMIT_FORM, {
    context: {
      headers: {
        token
      }
    },
    onError() {
      trackEvent({
        category,
        action,
        name: JSON.stringify({
          ...(data && {
            id: data?.id,
            title: data?.title
          })
        }),
        value: statusFail
      });
      gaEvent({
        category,
        action,
        name: JSON.stringify({
          ...(data && {
            id: data?.id,
            title: data?.title
          })
        }),
        value: statusFail
      });
    }
  });

  const [getForm] = useLazyQuery(GET_FORM_DATA, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token
      }
    },
    onCompleted(res) {
      if (res?.formSubmission?.submission) {
        form?.setFieldsValue({ ...res?.formSubmission?.submission });
        setDataLoading(false);
      }
    },
    onError() {
      setDataLoading(false);
    }
  });

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.submittedFormIds?.includes(data?.id)) {
        getForm({
          variables: {
            where: {
              id: data?.id
            }
          }
        });
      } else {
        setDataLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleSubmit = async (values) => {
    const payload = { ...values };
    if (actionType?.type === 'SUBMIT') {
      const variables = {
        data: {
          submission: payload
        },
        where: {
          id: data?.id
        }
      };
      const response = await submitForm({ variables });
      if (response?.data) {
        trackEvent({
          category: MATOMO_CATEGORY.FORM,
          action: MATOMO_ACTION.FORM_SIGNED,
          name: JSON.stringify({
            ...(data && {
              id: data?.id,
              title: data?.title
            })
          }),
          value: statusSuccess
        });
        gaEvent({
          category: MATOMO_CATEGORY.FORM,
          action: MATOMO_ACTION.FORM_SIGNED,
          name: JSON.stringify({
            ...(data && {
              id: data?.id,
              title: data?.title
            })
          }),
          value: statusSuccess
        });
        form?.resetFields();
      }
    } else {
      try {
        const response = await axios?.post(actionType?.url, payload);
        if (response?.data) {
          form?.resetFields();
        }
      } catch (error) {
        toast({
          message: 'Error occurred while submitting form data',
          type: 'error'
        });
      }
    }
  };

  return (
    <section className="donate-page form-display-wrapper inner-page">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerDescription={headerDescription}
        />
      </div>
      <div className="container-rnp">
        <div className="donate-block">
          {data?.title && (
            <div className="donate-amount ">
              <div className="form-title text-white-90">{data?.title}</div>
              {data?.description ? (
                <Paragraph
                  className="text-small text-white-90 vc-paragraph mb-16"
                  ellipsis={{ rows: 2 }}
                >
                  {data?.description}
                </Paragraph>
              ) : null}
            </div>
          )}
          <div className="donate-form">
            {dataLoading ? (
              <Loader />
            ) : (
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <div>
                  {data?.fields?.map((field) => {
                    return (
                      <div className="form-group" key={field?.key}>
                        <Form.Item
                          name={field?.key}
                          label={field?.label}
                          rules={[
                            {
                              required: field?.required,
                              message: `Please enter ${lowerCase(
                                field?.label
                              )}!`
                            }
                          ]}
                        >
                          {field?.type === 'text' ? (
                            <Input.TextArea
                              rows={3}
                              className="textarea"
                              placeholder={`Enter your ${field?.label}`}
                            />
                          ) : field?.type === 'dropdown' ? (
                            <Select
                              placeholder={`Select ${field?.label}`}
                              showSearch
                            >
                              {field?.options?.map((option) => (
                                <Select.Option
                                  key={option.key}
                                  value={option.key}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : (
                            <Input placeholder={`Enter your ${field?.label}`} />
                          )}
                        </Form.Item>
                      </div>
                    );
                  })}
                  <div className="d-flex flex-wrap form-btns gap-8">
                    {data?.cta?.length > 0 &&
                      data?.cta?.map((cta, index) => {
                        return (
                          <Button
                            icon={
                              cta?.icon?.url && (
                                <Image
                                  src={cta?.icon?.url}
                                  width={16}
                                  height={16}
                                  alt="icon"
                                />
                              )
                            }
                            // used index as not getting any unique value
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            onClick={() => {
                              trackEvent({
                                category: MATOMO_CATEGORY.BUTTON,
                                action: MATOMO_ACTION.CLICK,
                                name: MATOMO_NAME.BUTTON_CLICK,
                                customDimensions: [
                                  {
                                    id: actionDimensionIds.cta_title,
                                    value: cta?.label
                                  },
                                  {
                                    id: actionDimensionIds?.page_source,
                                    value: router.asPath
                                  },
                                  {
                                    id: actionDimensionIds.cta_target,
                                    value: cta?.actionUrl
                                  }
                                ]
                              });
                              setActionType({
                                type: cta?.action,
                                url: cta?.actionUrl
                              });
                              form?.submit();
                              setButtonClickedIndex(index);
                            }}
                            size="large"
                            type={
                              cta?.type !== 'PRIMARY' ? 'default' : 'primary'
                            }
                            className="d-flex align-center justify-center cs-button"
                            disabled={index === buttonClickedIndex && loading}
                            loading={index === buttonClickedIndex && loading}
                          >
                            {cta?.label}
                          </Button>
                        );
                      })}
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

FormDisplay.Loading = function Loading() {
  return <FormDisplayLoading />;
};

export default FormDisplay;
