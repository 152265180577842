import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Badge, Button, Tag, Typography } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CaretRight, PlayCircle, SketchLogo } from 'phosphor-react';
import {
  actionDimensionIds,
  COLOR_KEYS,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  MEMBERS_ONLY,
  ROUTES,
  TRACK_VALUE
} from '../../../../common/constants';
import useDeviceInfo from '../../../../common/useDeviceInfo';
import useNetworkType from '../../../../common/useNetworkInfo';
import {
  getUserCommonDimensions,
  secondsToMinute
} from '../../../../common/utils';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';
import FeaturedVideoCardLoading from '../../../skeleton/FeaturedVideoCardLoading';
import TagGrid from '../tagModule/TagGrid';

const { Paragraph } = Typography;
const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

function FeaturedVideoCard({
  tagName,
  tagHref = '#',
  heading,
  description,
  tagData,
  src,
  date,
  blurhash,
  href = '#',
  tagBackground = 'bg-blue-2',
  tagTextColor = 'text-n-900',
  showCTA,
  collections,
  isPremium,
  time,
  instanceData
}) {
  const {
    state: { placeholderImage, currentUser }
  } = useApp();
  const router = useRouter();
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();

  const handlePlay = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    router?.push(href);
  };

  const handleTopicsAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        topics: instanceData?.topics
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        topics: instanceData?.topics
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div className="feature-video-card-v2">
      <div className="feature-video-v2-img">
        <BlurHashImage
          src={src || placeholderImage}
          blurhash={blurhash}
          fill
          alt={heading}
          className="contain"
        />
      </div>
      <div className="feature-video-v2-des">
        <div className="fvc-description">
          {tagName && (
            <CustomAnchor
              href={tagHref}
              onClick={() =>
                handleTopicsAnalytics(
                  MATOMO_CATEGORY.TOPICS,
                  MATOMO_ACTION.TOPICS_CARD_CLICKED
                )
              }
            >
              <Badge
                className={`badge-md text-white-90 ${
                  COLOR_KEYS[tagBackground]?.backgroundClass ?? ''
                } ${tagTextColor}`}
              >
                {tagName}
              </Badge>
            </CustomAnchor>
          )}

          {collections?.length > 0 && (
            <div className="tag-carousel mb-12">
              {collections?.map((collection) => (
                <CustomAnchor
                  key={collection?.id}
                  href={`${ROUTES.COLLECTIONS}/${collection?.slug}`}
                  className="mr-4"
                >
                  <Tag className="text-md tag-xs bg-n-0 b-0 text-bg-ivory">
                    {collection?.title}
                  </Tag>
                </CustomAnchor>
              ))}
            </div>
          )}
          {isPremium ? (
            <Badge className="badge-md text-white-90 bg-n-900 premium-tag ml-4">
              <SketchLogo size={18} />
              <span className="text-label-xs">{MEMBERS_ONLY}</span>
            </Badge>
          ) : null}
          {heading && <h2 className="heading">{heading}</h2>}
          <div className="d-flex align-center mt-12 mb-12">
            <div className="d-flex align-center">
              {date && <span className="date">{date}</span>}
              {time ? (
                <span className={`date ${date ? 'ml-8' : ''}`}>
                  {secondsToMinute(time)}{' '}
                </span>
              ) : null}
            </div>
          </div>

          {description && (
            <Paragraph className="description text-n-0">
              {description}
            </Paragraph>
          )}
          <TagGrid
            className="text-md tag-xs bg-tags b-0 text-n-0 mr-4"
            data={tagData}
            icon={<CaretRight className="ml-2" size={16} />}
          />
        </div>
        {showCTA && (
          <div className="slider-button">
            <Button
              className="cs-button"
              type="primary"
              size="large"
              block
              onClick={() => {
                trackEvent({
                  category: MATOMO_CATEGORY.BUTTON,
                  action: MATOMO_ACTION.CLICK,
                  name: MATOMO_NAME.BUTTON_CLICK,
                  customDimensions: [
                    {
                      id: actionDimensionIds.page_source,
                      value: router.asPath
                    },
                    {
                      id: actionDimensionIds.cta_title,
                      value: 'Play'
                    },
                    ...getUserCommonDimensions(currentUser, device, networkType)
                  ]
                });
                handlePlay(
                  MATOMO_CATEGORY.VIDEO,
                  MATOMO_ACTION.FEATURED_VIDEO_BTN_CLICKED
                );
              }}
            >
              <PlayCircle className="mr-4 vertical-middle" size={24} />
              Play
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

FeaturedVideoCard.Loading = function Loading() {
  return <FeaturedVideoCardLoading />;
};

export default FeaturedVideoCard;
