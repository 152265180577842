import { useQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Typography } from 'antd';
import { capitalize, uniqBy } from 'lodash';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { X } from 'phosphor-react';
import { useMemo, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  PAGE_GROUPS,
  ROUTES,
  SUBSCRIPTION_PLAN_KEYS,
  TRACK_VALUE
} from '../../../../common/constants';
import useDeviceInfo from '../../../../common/useDeviceInfo';
import useNetworkType from '../../../../common/useNetworkInfo';
import usePageGroup from '../../../../common/usePageGroup';
import { encode, getUserCommonDimensions } from '../../../../common/utils';
import { SUBSCRIPTION_PLANS } from '../../../../graphql/Queries';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';
import SubscriptionBlockLoading from '../../../skeleton/SubscriptionBlockLoading';

const { Title } = Typography;
function SubscriptionBlock({
  primaryAction,
  description = 'Join the Insider Club and Unlock the Full Potential',
  btnLabel = 'SUBSCRIBE',
  modal,
  setShowSubscribeModal,
  loginText,
  requiredPlan
}) {
  const {
    state: { accessToken, currentUser, loading, isAuthenticated }
  } = useApp();
  const { trackEvent } = useMatomo();
  const router = useRouter();
  const pathname = usePathname();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const [planNames, setPlanNames] = useState([]);
  const [lowestPrice, setLowestPrice] = useState();
  const [requiredPlanWeight, setRequiredPlanWeight] = useState(0);

  const { getRandomPath } = usePageGroup();

  useQuery(SUBSCRIPTION_PLANS, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token: accessToken
      }
    },
    onCompleted: (res) => {
      const prices = res?.subscriptionPlans?.subscriptionPlans
        ?.filter((plan) =>
          requiredPlan
            ? capitalize(plan.name) ===
              capitalize(requiredPlan?.split('_')?.[0])
            : plan.amount.usd.price
        )
        ?.map((plan) => plan.amount.usd.price);
      const lowPrice = Math?.min(...prices);
      setLowestPrice(lowPrice);

      const requiredPlanObj = res?.subscriptionPlans?.subscriptionPlans?.find(
        (plan) => plan.key === (requiredPlan ?? currentUser?.currentPlan)
      );
      setRequiredPlanWeight(requiredPlanObj?.weight);

      const uniquePlansMap = res?.subscriptionPlans?.subscriptionPlans.map(
        (item) => ({
          name: item.name,
          weight: item.weight
        })
      );
      const uniquePlans = uniqBy(uniquePlansMap, 'name');
      setPlanNames(uniquePlans);
    }
  });

  const hasActivePlan =
    currentUser &&
    ![SUBSCRIPTION_PLAN_KEYS.FREE, SUBSCRIPTION_PLAN_KEYS.GUEST].includes(
      currentUser?.currentPlan
    );

  const hasActiveHeavyWeightPlan =
    hasActivePlan &&
    [
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_ANNUALLY,
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_MONTHLY
    ].includes(currentUser?.currentPlan);

  const currentPlanWeight = currentUser
    ? Math.floor(currentUser?.currentSubscriptionPlan?.planWeight)
    : 0;

  const plansToUpgrade =
    currentPlanWeight >= requiredPlanWeight
      ? ''
      : planNames
          ?.filter(
            (plan) =>
              Math.floor(plan.weight) >= Math.floor(requiredPlanWeight) &&
              Math.floor(plan.weight) !== currentPlanWeight
          )
          ?.map((plan) => plan.name)
          ?.join(' or ');

  const buttonText = useMemo(() => {
    if (loading) return '';
    if (hasActiveHeavyWeightPlan) return `Change Subscription`;
    if (hasActivePlan) return `Upgrade`;
    return btnLabel;
  }, [hasActiveHeavyWeightPlan, hasActivePlan, loading, btnLabel]);

  const handleRedirect = () => {
    const path = getRandomPath(PAGE_GROUPS.SUBSCRIPTION);
    if (path)
      router.push({
        pathname: path,
        query: {
          redirectPath: pathname
        }
      });
  };
  return (
    <section
      className={`subscription-block-wrapper ${
        modal ? 'modal-subscription' : ''
      }`}
    >
      <Button
        className="sb-cancel"
        type="text"
        onClick={() => {
          setShowSubscribeModal(false);
        }}
      >
        <X size={26} />
      </Button>
      <div className="container">
        <div className="subscription-block">
          <div className="sb-content">
            <Title level={5} className="sb-content-description">
              {hasActivePlan
                ? `You are a ${currentUser?.currentSubscriptionPlan?.planName}`
                : description}
            </Title>
            <Title level={4}>
              {hasActivePlan ? (
                <span>
                  Upgrade to <b>{capitalize(plansToUpgrade)}</b> to watch this
                  content
                </span>
              ) : (
                <span>
                  Watch this video and access full video library by becoming a
                  &nbsp;
                  <b>BREITBART FIGHT CLUB</b> member.
                </span>
              )}
            </Title>
            {requiredPlanWeight > 0 && !hasActivePlan && (
              <div className="w-full text-left">
                {lowestPrice && (
                  <span className="plan-price">
                    Plans starting from ${lowestPrice}/month
                  </span>
                )}
                <div className="d-flex flex-wrap gap-8 mt-4 w-full justify-center">
                  {planNames?.slice(requiredPlanWeight - 1)?.map((plan) => {
                    return (
                      <div className="plan-name" key={plan?.weight}>
                        {plan?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {primaryAction && (
              <Button
                className="cta-btn"
                loading={loading}
                size="small"
                type="primary"
                onClick={() => {
                  trackEvent({
                    category: MATOMO_CATEGORY.BUTTON,
                    action: MATOMO_ACTION.CLICK,
                    name: MATOMO_NAME.BUTTON_CLICK,
                    customDimensions: [
                      {
                        id: actionDimensionIds.cta_title,
                        value: buttonText
                      },
                      {
                        id: actionDimensionIds.page_source,
                        value: router.asPath
                      },
                      ...getUserCommonDimensions(
                        currentUser,
                        device,
                        networkType
                      )
                    ],
                    value: TRACK_VALUE.SUCCESS
                  });
                  handleRedirect();
                }}
              >
                {buttonText}
              </Button>
            )}
            {!isAuthenticated && (
              <div className="sb-footer">
                <span>{loginText}</span> &nbsp;
                <CustomAnchor
                  href={{
                    pathname: ROUTES.LOGIN,
                    query: {
                      q: encode(pathname)
                    }
                  }}
                >
                  Login
                </CustomAnchor>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

SubscriptionBlock.Loading = function Loading() {
  return <SubscriptionBlockLoading />;
};

export default SubscriptionBlock;
