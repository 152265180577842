import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Tooltip } from 'antd';
import { useRouter } from 'next/router';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import {
  actionDimensionIds,
  CONTENT_CATEGORY,
  CONTENT_TYPE,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  TRACK_VALUE
} from '../../../../common/constants';
import useDeviceInfo from '../../../../common/useDeviceInfo';
import useNetworkType from '../../../../common/useNetworkInfo';
import { getUserCommonDimensions } from '../../../../common/utils';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import {
  FacebookShare,
  LinkedinShare,
  TelegramShare,
  TwitterShare,
  WhatsappShare
} from '../../../imageComponent/socialIcon';

function ShareSheet({ shareUrl, videos }) {
  const {
    state: { currentUser }
  } = useApp();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const { trackEvent } = useMatomo();
  const router = useRouter();

  const formContentDimension = (platform) => {
    const dimensions = [
      { id: actionDimensionIds.content_id, value: videos?.[0]?.id },
      { id: actionDimensionIds.content_title, value: videos?.[0]?.title },
      { id: actionDimensionIds.content_slug, value: videos?.[0]?.url },
      { id: actionDimensionIds.content_category, value: CONTENT_CATEGORY },
      { id: actionDimensionIds.content_type, value: CONTENT_TYPE.AUDIO },
      { id: actionDimensionIds.share_platform, value: platform },
      ...getUserCommonDimensions(currentUser, device, networkType)
    ];

    return dimensions;
  };

  const handleShare = (platform, isEmail = false) => {
    trackEvent({
      category: MATOMO_CATEGORY.PODCAST_AUDIO,
      action: MATOMO_ACTION.CLICK,
      name: isEmail ? MATOMO_NAME.SHARE_EMAIL : MATOMO_NAME.SHARE_SOCIAL_MEDIA,
      customDimensions: formContentDimension(platform),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category: MATOMO_CATEGORY.PODCAST,
      action: MATOMO_ACTION.PODCAST_SHARED,
      name: JSON.stringify({
        action: MATOMO_ACTION.PODCAST_SHARED,
        userId: currentUser?.id ?? '',
        title: videos?.[0]?.title,
        id: videos?.[0]?.id,
        url: videos?.[0]?.url
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div className="d-flex flex-vertical">
      <div className="social-share-icon">
        <FacebookShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('Facebook', false);
          }}
        >
          <FacebookShare />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('Twitter', false);
          }}
        >
          <TwitterShare />
        </TwitterShareButton>
        <TelegramShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('Telegram', false);
          }}
        >
          <TelegramShare />
        </TelegramShareButton>
        <LinkedinShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('LinkedIn', false);
          }}
        >
          <LinkedinShare />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('Whatsapp', false);
          }}
        >
          <WhatsappShare />
        </WhatsappShareButton>
        <InstapaperShareButton
          url={shareUrl}
          onClick={() => {
            handleShare('Instagram', false);
          }}
        >
          <InstapaperIcon size={48} round />
        </InstapaperShareButton>
        <EmailShareButton
          openShareDialogOnClick
          url={shareUrl}
          onClick={() => {
            handleShare('Email', true);
          }}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
      </div>
      <div className="share-item d-flex">
        <p>{shareUrl}</p>
        <CopyToClipboard
          // eslint-disable-next-line no-undef
          text={shareUrl}
        >
          <Tooltip
            title="Copied!"
            trigger="click"
            onClick={(e) => e?.stopPropagation()}
          >
            <Button
              className="cs-button"
              type="primary"
              onClick={() => {
                trackEvent({
                  category: MATOMO_CATEGORY.VIDEO,
                  action: MATOMO_ACTION.CLICK,
                  name: MATOMO_NAME.BUTTON_CLICK,
                  value: TRACK_VALUE.SUCCESS,
                  customDimensions: [
                    { id: actionDimensionIds.cta_title, value: 'Copy' },
                    {
                      id: actionDimensionIds.page_source,
                      value: router.asPath
                    },
                    ...getUserCommonDimensions(currentUser, device, networkType)
                  ]
                });
                handleShare();
              }}
            >
              Copy
            </Button>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default ShareSheet;
