import React from 'react';

function Progress({ loaded, played, onChange, handleMouseDown }) {
  return (
    <div className="progress-wrap">
      <input
        aria-label="timeline"
        max={1}
        min={0}
        onChange={(event) => onChange(event)}
        type="range"
        step="any"
        value={played}
        onMouseDown={() => {
          handleMouseDown();
        }}
      />
      <progress id="progress-played" max={1} value={played} />
      <progress id="progress-loaded" max={1} value={loaded} />
    </div>
  );
}

export default Progress;
