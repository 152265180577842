import React from 'react';

function Progress({
  loaded,
  played,
  onChange,
  handleMouseDown,
  handleMouseUp
}) {
  return (
    <div className="progress-wrap">
      <input
        aria-label="timeline"
        max={1}
        min={0}
        onChange={(event) => onChange(event)}
        onMouseDown={() => {
          handleMouseDown();
        }}
        onMouseUp={() => {
          handleMouseUp();
        }}
        type="range"
        step="any"
        value={played}
      />
      <progress id="progress-played" max={1} value={played} />
      <progress id="progress-loaded" max={1} value={loaded} />
    </div>
  );
}

export default Progress;
