import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Modal } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  ROUTES,
  STATIC_DATA_KEYS,
  staticUiLabels,
  TRACK_VALUE
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import {
  encode,
  getUserCommonDimensions,
  staticDataApi
} from '../common/utils';
import { useApp } from '../providers/AppContext';

function SignupCTAModal({ open, onClose, isAuth }) {
  const router = useRouter();
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const {
    state: { currentUser }
  } = useApp();

  const [{ title, description, btnLabel }, setData] = useState({
    title: 'Create An Account',
    description: 'Join us for more informational content.',
    btnLabel: 'Sign Up'
  });
  const formContentDimension = (label, target) => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );

    const contentObjectForVideo = [
      { id: actionDimensionIds.page_source, value: router.asPath },
      { id: actionDimensionIds.cta_target, value: target },
      { id: actionDimensionIds.cta_title, value: label },
      ...userDimensions
    ];

    return contentObjectForVideo;
  };

  useEffect(() => {
    (async () => {
      if (isAuth) return;
      try {
        const resp = await staticDataApi(
          `${STATIC_DATA_KEYS.UI_LABELS}/en.json`
        );
        const jsonData = resp?.data;
        if (jsonData) {
          const {
            VIDEO_SIGNUP_POPUP_BUTTON_LABEL,
            VIDEO_SIGNUP_POPUP_DESCRIPTION,
            VIDEO_SIGNUP_POPUP_TITLE
          } = jsonData ?? {};
          setData({
            title:
              VIDEO_SIGNUP_POPUP_TITLE?.value ||
              staticUiLabels.VIDEO_SIGNUP_POPUP_TITLE,
            description:
              VIDEO_SIGNUP_POPUP_DESCRIPTION?.value ||
              staticUiLabels.VIDEO_SIGNUP_POPUP_DESCRIPTION,
            btnLabel:
              VIDEO_SIGNUP_POPUP_BUTTON_LABEL?.value ||
              staticUiLabels.VIDEO_SIGNUP_POPUP_BUTTON_LABEL
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('ERROR', err);
      }
    })();
  }, [isAuth]);

  const handleSignupButtonClick = () => {
    const encodedQueryParams = encode(router?.asPath);
    router?.push({
      pathname: ROUTES.SIGN_UP,
      query: {
        q: encodedQueryParams
      }
    });
    trackEvent({
      category: MATOMO_CATEGORY.BUTTON,
      action: MATOMO_ACTION.CLICK,
      name: MATOMO_NAME.BUTTON_CLICK,
      customDimensions: formContentDimension(btnLabel, ROUTES.SIGN_UP),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <Modal
      className="bg-ivory cta-modal"
      open={open}
      closable={false}
      width={400}
      footer={null}
      onCancel={onClose}
    >
      <div className="text-center">
        <h3 className="display-h3 text-black-90 mb-4">{title}</h3>
        <p className="paragraph-m mb-32 text-black-70">{description}</p>
        <Button
          key="link"
          className="m-auto cs-button between"
          type="primary"
          size="large"
          onClick={handleSignupButtonClick}
        >
          {btnLabel}
        </Button>
      </div>
    </Modal>
  );
}

export default SignupCTAModal;
